import { useLocation } from '@reach/router'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, PaymentIntent, SetupIntent } from '@stripe/stripe-js'
import { navigate } from 'gatsby'
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import config from '../../../config'
import { colors } from '../../../theme'
import { UpdateCardDatailsForm } from '../UpdateCardDetailsPage/components/UpdateCardDetailsForm'

interface IProps {
  seti_clientSecret: string | null
  pi_clientSecret: string | null
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ErrorMessage = styled.div`
  text-align: center;
  max-width: 400px;
  background-color: ${colors.red};
  padding: 10px;
  border-radius: 8px;
  margin: 25px auto 10px auto;
  color: ${colors.white};
  a {
    text-decoration: underline;
  }
`

const SubscribeConfirmPageWithElements = (props: IProps) => {
  const location = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent>()
  const [setupIntent, setSetupIntent] = useState<SetupIntent>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const retrieveIntent = async () => {
    if (!stripe) return
    if (props.pi_clientSecret) {
      const intentResult = await stripe?.retrievePaymentIntent(
        props.pi_clientSecret
      )
      if (intentResult.error) {
        setErrorMessage(intentResult.error.message)
        return
      }
      setPaymentIntent(intentResult.paymentIntent)
    } else if (props.seti_clientSecret) {
      const intentResult = await stripe?.retrieveSetupIntent(
        props.seti_clientSecret
      )
      if (intentResult.error) {
        setErrorMessage(intentResult.error.message)
        return
      }
      setSetupIntent(intentResult.setupIntent)
    }
  }

  const status = useMemo(() => {
    if (!paymentIntent && !setupIntent) return 'LOADING'
    if (
      paymentIntent?.status === 'requires_confirmation' ||
      setupIntent?.status === 'requires_confirmation'
    )
      return 'REQUIRES_CONFIRMATION'
    if (
      paymentIntent?.status === 'requires_payment_method' ||
      setupIntent?.status === 'requires_payment_method'
    )
      return 'REQUIRES_PAYMENT_METHOD'
    if (
      paymentIntent?.status === 'succeeded' ||
      setupIntent?.status === 'succeeded'
    )
      return 'SUCCESS'
    return false
  }, [paymentIntent, setupIntent])

  const confirmPayment = async () => {
    if (!props.pi_clientSecret) return
    if (elements && status === 'REQUIRES_PAYMENT_METHOD') {
      const data = await elements.submit()
      if (data.error) {
        setErrorMessage(data.error.message || 'Unknown error')
        return
      }
    }
    const result = await stripe?.confirmPayment({
      clientSecret: props.pi_clientSecret,
      elements:
        elements && status === 'REQUIRES_PAYMENT_METHOD' ? elements : undefined,
      confirmParams: {
        return_url: `${location.origin}/subscribe/confirm?pi_client_secret=${props.pi_clientSecret}`,
      },
      redirect: 'always',
    })
    if (result?.error?.message) {
      setErrorMessage(result.error.message)
    }
  }

  const confirmSetup = async () => {
    if (!props.seti_clientSecret) return
    if (elements) {
      const data = await elements.submit()
      if (data.error) {
        setErrorMessage(data.error.message || 'Unknown error')
        return
      }
    }
    const result = await stripe?.confirmSetup({
      elements: elements || undefined,
      clientSecret: props.seti_clientSecret,
      confirmParams: {
        return_url: `${location.origin}/subscribe/confirm?seti_client_secret=${props.seti_clientSecret}`,
      },
      redirect: 'always',
    })
    if (result?.error?.message) {
      setErrorMessage(result.error.message)
    }
  }

  useEffect(() => {
    if (status === 'SUCCESS') {
      navigate(`/account?forceRefetch=true`)
    }
  }, [status])

  useEffect(() => {
    retrieveIntent()
  }, [stripe])

  return (
    <Wrapper>
      {status === 'LOADING' && 'Loading...'}
      {status !== 'REQUIRES_PAYMENT_METHOD' && errorMessage && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
      {paymentIntent && status === 'REQUIRES_CONFIRMATION' && (
        <Button size="regular" theme="primary" onClick={confirmPayment}>
          Confirm
        </Button>
      )}
      {setupIntent && status === 'REQUIRES_CONFIRMATION' && (
        <Button size="regular" theme="primary" onClick={confirmSetup}>
          Confirm
        </Button>
      )}
      {status === 'REQUIRES_PAYMENT_METHOD' && (
        <form>
          <UpdateCardDatailsForm
            title="Payment details"
            error={errorMessage || null}
            onSubmit={async () => {
              if (props.seti_clientSecret) await confirmSetup()
              else if (props.pi_clientSecret) await confirmPayment()
            }}
          />
        </form>
      )}
    </Wrapper>
  )
}

export const SubscribeConfirmPage = (props: IProps) => {
  const stripePromise = useMemo(() => loadStripe(config.stripeKey), [])

  if (!props.seti_clientSecret && !props.pi_clientSecret) return null

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret:
          props.seti_clientSecret || props.pi_clientSecret || undefined,
      }}
    >
      <SubscribeConfirmPageWithElements {...props} />
    </Elements>
  )
}
