import React, { useState } from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import styled from 'styled-components'
import { Button } from 'nzk-react-components'
import { colors } from '../../../../theme'

const Wrapper = styled.div`
  max-width: 400px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  color: #212121;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 6px 0px #afafaf;
  gap: 10px;
  .submit-button {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
  .error {
    background-color: #f44336;
    color: #fff;
    font-family: 'Libre Baskerville', serif;
    padding: 8px;
    border-radius: 8px;
  }
  h2 {
    font-family: 'Rammetto One', cursive;
    color: ${colors.purple};
    font-weight: normal;
  }
`

interface IProps {
  title?: string
  onSubmit: () => Promise<void>
  error: string | null
}

export const UpdateCardDatailsForm = (props: IProps) => {
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    if (loading) return
    setLoading(true)
    await props.onSubmit()
    setLoading(false)
  }

  return (
    <Wrapper>
      <h2>{props.title || 'Update card details'}</h2>
      <PaymentElement />
      {props.error && <div className="error">{props.error}</div>}
      <div className="submit-button">
        <Button theme="primary" size="small" onClick={onSubmit}>
          Submit
        </Button>
      </div>
    </Wrapper>
  )
}
