import { useLocation } from '@reach/router'
import { SubscribeConfirmPage } from 'components/pages/SubscribePage/SubscribeConfirmPage'
import Layout from 'layouts/layout'
import React, { useMemo } from 'react'

export default () => {
  const location = useLocation()

  const setiClientSecret = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('seti_client_secret')
  }, [location.search])

  const piClientSecret = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('pi_client_secret')
  }, [location.search])

  if (typeof window === 'undefined') return null

  return (
    <Layout>
      {!setiClientSecret && !piClientSecret ? (
        <div>No client secret</div>
      ) : (
        <SubscribeConfirmPage
          pi_clientSecret={piClientSecret}
          seti_clientSecret={setiClientSecret}
        />
      )}
    </Layout>
  )
}
